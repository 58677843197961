export const Links = {
  socialMedia: {
    instagram: 'https://www.instagram.com/temploenkoji/',
    facebook: 'https://www.facebook.com/Templo-Enkoji-702093283924657',
    whatsapp: 'https://api.whatsapp.com/send?phone=5511913529667&text=Ol%C3%A1,%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20Templo%20Enkoji%20!%20%E2%98%B8%EF%B8%8E',
    youtube: ' https://www.youtube.com/@enkojitemplo',
    maps: 'https://g.page/r/CbW4barwEzszEBM/review',
    shop: 'https://www.enkoji.com.br/',
    email: 'mailto:templo.enkoji@gmail.com'
  },
  track: {
    waze: 'https://ul.waze.com/ul?place=ChIJhUh0Wn6yz5QRtbhtqvATOzM&ll=-23.74567900%2C-46.86607150&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location',
    maps: 'https://g.page/r/CbW4barwEzszEBM'
  },
  schedule: 'https://calendar.google.com/calendar/u/6/r/month/2023/7/1',
  retreat: 'https://www.meuretiro.com.br/experiencia/4so5t9w1/Templo_Enkoji-Mizen_Fotografia_Contemplativa',
  joinUs: 'https://linktr.ee/temploenkoji',
}
